import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import slugify from "slugify"
import styled from "styled-components"
import Layout from "components/Layout"
import ArticleHeader from "components/ArticleHeader"
import BrandGroups from "components/BrandElement/Groups"
import Measurements from "components/Measurements"
import SEO from "components/SEO"

const StyledPartnerPage = styled.section`
  padding: 4rem 2rem;

  h1 {
    font-size: 2.4rem;
  }

  .brand {
    color: ${(props) => props.theme.colors.secondary};
  }

  ${(props) => props.theme.media.tablet_portrait_up`
    h1 {
      max-width: 64rem;
    }
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    max-width: 100rem;
    margin: 0 auto;
    padding-bottom: 10rem;

    h1 {
      font-size: 3.6rem;
      text-align: left;
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    h1 {
      max-width: none;
    }
  `}
`

const StyledArticleHeader = styled(ArticleHeader)``

const StyledMeasurements = styled(Measurements)`
  margin-top: 2rem;
  .item {
    svg {
      width: 4rem;
      height: 4rem;
    }
  }
`

function Partner({
  data: {
    ltowrPartners: {
      name,
      api_key,
      landing_page_subtitle,
      landing_page_title,
      language,
    },
    prismic: { brand_element_group, site_settings },
  },
}) {
  const uri = slugify(name)

  function renderTitle() {
    const headingParts = landing_page_title.split("{{name}}")
    return (
      <h1>
        {headingParts[0]} <span className="brand">{name}</span>{" "}
        {headingParts[1]}
      </h1>
    )
  }

  const container = useRef(null)

  useEffect(() => {
    const script = document.createElement("script")

    if (typeof window !== undefined && container.current !== null) {
      script.id = "DKOpsamlingWidget"
      script.src = "https://cdn.danmarksopsamling.dk/js/collection-widget.js"
      script.async = true
      container.current.appendChild(script)
    }

    return () => {
      container.current.removeChild(script)
    }
  }, [])

  return (
    <Layout site_settings={site_settings}>
      <div ref={container}></div>
      <SEO
        hideFromGoogle
        slug={`/${uri}`}
        title={`Hjælp ${name} med at samle affald`}
      />
      <BrandGroups
        items={[{ brand_element_group_item: brand_element_group }]}
      />
      <StyledPartnerPage>
        <header>
          {landing_page_title ? (
            renderTitle()
          ) : (
            <h1>
              Hjælp <span className="brand">{name}</span> med at samle affald op
            </h1>
          )}
          {landing_page_subtitle && <h3>{landing_page_subtitle}</h3>}
        </header>
        <div
          id="LTOWR-collection-widget"
          key={`widget`}
          data-apiKey={`${api_key}`}
          data-lang={language}
        ></div>
        <StyledMeasurements lang={language || "da"} includeExample />
      </StyledPartnerPage>
    </Layout>
  )
}

export default Partner

Partner.defaultProps = {}

Partner.propTypes = {}

export const query = graphql`
  query Partner($uid: String!) {
    ltowrPartners(uid: { eq: $uid }) {
      id
      api_key
      name
      uid
      landing_page_subtitle
      landing_page_title
      language
    }
    prismic {
      brand_element_group(uid: "layout", lang: "da-dk") {
        _meta {
          id
          uid
          lang
        }
        brand_group {
          color
          graphic
          parallax
        }
      }
      site_settings(lang: "da-dk", uid: "site-settings") {
        ...Footer
      }
    }
  }
`
